import React from 'react';
import Header from '../Header';
import Code from '../Code';

function Operators(props) {
  return (
    <div className="section">
      <p>Na zmiennych i stałych (lub bezpośrednio na wartościach) możemy wykonywać różne operacje matematyczne i logiczne.</p>
      <Header type="h1" text="Operatory arytmetyczne"/>
      <table>
            <tr>
                <th>Operator</th>
                <th>Opis</th>
            </tr>
            <tr>
                <td><Code language="js" inline code='a = b + c;'></Code></td>
                <td>dodawanie</td>
            </tr>
            <tr>
                <td><Code language="js" inline code='a = b – c;'></Code></td>
                <td>odejmowanie</td>
            </tr>
            <tr>
                <td><Code language="js" inline code='a = b * c;'></Code></td>
                <td>mnożenie</td>
            </tr>
            <tr>
                <td><Code language="js" inline code='a = b / c;'></Code></td>
                <td>dzielenie</td>
            </tr>
            <tr>
                <td><Code language="js" inline code='a = 10 % 4;'></Code></td>
                <td>modulo (reszta z dzielenia)</td>
            </tr>
            <tr>
                <td><Code language="js" inline code='a = b ** c;'></Code></td>
                <td>podniesienie do potęgi (<Code language="js" inline code='b'></Code> do potęgi <Code language="js" inline code='c'></Code>)</td>
            </tr>
            <tr>
                <td><Code language="js" inline code='++a;'></Code></td>
                <td>inkrementacja (zwiększenie o 1) w tej samej linii kodu</td>
            </tr>
            <tr>
                <td><Code language="js" inline code='a++;'></Code></td>
                <td>inkrementacja (zwiększenie o 1) w następnej linii kodu</td>
            </tr>
            <tr>
                <td><Code language="js" inline code='--a;'></Code></td>
                <td>dekrementacja (zmniejszenie o 1) w tej samej linii kodu</td>
            </tr>
            <tr>
                <td><Code language="js" inline code='a--;'></Code></td>
                <td>dekrementacja (zmniejszenie o 1) w następnej linii kodu</td>
            </tr>
        </table>
        <p>Tak jak w matematyce, możemy w JS używać nawiasów, aby zmodyfikować kolejność wykonywanych działań:</p>
      <Code language="javascript" code={`
      console.log( 2 + (3 * 2) ); // 8
      console.log( (2 + 3) * 2 ); // 10
      `}></Code>
      <Header type="h1" text="Operatory przypisania"/>
      <table>
            <tr>
                <th>Operator</th>
                <th>Przykład</th>
                <th>Równoznaczne z</th>
            </tr>
            <tr>
                <td><Code language="js" inline code='='/></td>
                <td><Code language="js" inline code='a = b'/></td>
                <td><Code language="js" inline code='a = b'/></td>
            </tr>
            <tr>
                <td><Code language="js" inline code='+='/></td>
                <td><Code language="js" inline code='a += b'/></td>
                <td><Code language="js" inline code='a = a + b'/></td>
            </tr>
            <tr>
                <td><Code language="js" inline code='-='/></td>
                <td><Code language="js" inline code='a -= b'/></td>
                <td><Code language="js" inline code='a = a - b'/></td>
            </tr>
            <tr>
                <td><Code language="js" inline code='*='/></td>
                <td><Code language="js" inline code='a *= b'/></td>
                <td><Code language="js" inline code='a = a * b'/></td>
            </tr>
            <tr>
                <td><Code language="js" inline code='/='/></td>
                <td><Code language="js" inline code='a /= b'/></td>
                <td><Code language="js" inline code='a = a / b'/></td>
            </tr>
            <tr>
                <td><Code language="js" inline code='%='/></td>
                <td><Code language="js" inline code='a %= b'/></td>
                <td><Code language="js" inline code='a = a % b'/></td>
            </tr>
        </table>

        <Header type="h1" text="Operatory porównania"/>
        <table>
            <tr>
                <th>Operator</th>
                <th>Opis</th>
            </tr>
            <tr>
                <td><Code language="js" inline code='=='/></td>
                <td>równa wartość</td>
            </tr>
            <tr>
                <td><Code language="js" inline code='==='/></td>
                <td>równa wartość i typ danych</td>
            </tr>
            <tr>
                <td><Code language="js" inline code='!='/></td>
                <td>różne wartości</td>
            </tr>
            <tr>
                <td><Code language="js" inline code='!=='/></td>
                <td>różne wartości i typy danych</td>
            </tr>
            <tr>
                <td><Code language="js" inline code='>'/></td>
                <td>większe niż</td>
            </tr>
            <tr>
                <td><Code language="js" inline code='<'/></td>
                <td>mniejsze niż</td>
            </tr>
            <tr>
                <td><Code language="js" inline code='>='/></td>
                <td>większe lub równe</td>
            </tr>
            <tr>
                <td><Code language="js" inline code='<='/></td>
                <td>mniejsze lub równe</td>
            </tr>
        </table>
        <Header type="h1" text="Operatory logiczne"/>
        <table>
            <tr>
                <th>Operator</th>
                <th>Opis</th>
            </tr>
            <tr>
                <td><Code language="js" inline code='&&'/></td>
                <td>i (and)</td>
            </tr>
            <tr>
                <td><Code language="js" inline code='||'/></td>
                <td>lub (or)</td>
            </tr>
            <tr>
                <td><Code language="js" inline code='^'/></td>
                <td>jeden z, ale nie oba naraz (xor)</td>
            </tr>
            <tr>
                <td><Code language="js" inline code='!'/></td>
                <td>negacja (not)</td>
            </tr>
        </table>

        <Header type="h1" text="Operator warunkowy"/>
        <p>Znany również jako operator trójczłonowy (conditional/ternary operator), potocznie nazywany często "krótkim ifem" (short if), służy zwykle do przypisywania zmiennej/stałej wartości zależnej od jakiegoś warunku.</p>
        <Header type="h5" text="Składnia"/>
        <Code language='js' code='condition ? valueIfTrue : valueIfFalse' />
        <Header type="h1" text="Działania matematyczne"/>
        <p>JavaScript udostępnia obiekt <Code language='js' inline code='Math()' />, dzięki któremu z łatwością możemy przeprowadzać bardziej złożone operacje matematyczne.</p>
        <table>
            <tr>
                <th>Funkcja</th>
                <th>Opis</th>
            </tr>
            <tr>
                <td><Code language="js" inline code='Math.round(a)'/></td>
                <td>zaokrąglenie standardowe – mniejsze od <Code inline language='js' code='0.5'/> w dół, a <Code inline language='js' code='0.5'/> i większe w górę</td>
            </tr>
            <tr>
                <td><Code language="js" inline code='Math.ceil(a)'/></td>
                <td>	zaokrąglenie w górę</td>
            </tr>
            <tr>
                <td><Code language="js" inline code='Math.floor(a)'/></td>
                <td>zaokrąglenie w dół</td>
            </tr>
            <tr>
                <td><Code language="js" inline code='Math.pow(a,b);'/></td>
                <td>potęgowanie – <Code inline language='js' code='a'/> do potęgi <Code inline language='js' code='b'/></td>
            </tr>
            <tr>
                <td><Code language="js" inline code='Math.sqrt(a);'/></td>
                <td>pierwiastek kwadratowy z <Code inline language='js' code='a'/></td>
            </tr>
            <tr>
                <td><Code language="js" inline code='Math.min(a, b, c);'/></td>
                <td>najmniejsza z podanych wartości</td>
            </tr>
            <tr>
                <td><Code language="js" inline code='Math.max(a, b, c);'/></td>
                <td>największa z podanych wartości</td>
            </tr>
            <tr>
                <td><Code language="js" inline code='Math.random();'/></td>
                <td>losowa liczba z przedziału od <Code inline language='js' code='0'/> do <Code inline language='js' code='1'/> (bez <Code inline language='js' code='1'/>, czyli od <Code inline language='js' code='0'/> do <Code inline language='js' code='0.999'/>...)</td>
            </tr>
        </table>
        <p>Powyższe działania można łączyć ze sobą, np. aby uzyskać losową liczbę całkowitą z przedziału od <Code inline language='js' code='1'/> do <Code inline language='js' code='10'/>:</p>
        <Code language='js' code='const randomNumber = Math.floor(Math.random() * 10) + 1;'/>
    </div>
  );
} 

export default Operators;
