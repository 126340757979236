import './App.css';
import Content from './content';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Home from './components/Subpages/home';
import AddingScripts from './components/Subpages/adding-scripts';
import Comments from './components/Subpages/comments';
import TargetDomElements from './components/Subpages/target-dom-elements';
import Output from './components/Subpages/output';
import ModifyDomElements from './components/Subpages/modify-dom-elements';
import GoodPractices from './components/Subpages/good-practices';
import Variables from './components/Subpages/variables';
import DataTypes from './components/Subpages/data-types';
import Operators from './components/Subpages/operators';
import Arrays from './components/Subpages/arrays';
import Objects from './components/Subpages/objects';
import Conditions from './components/Subpages/conditions';
import Loops from './components/Subpages/loops';
import Functions from './components/Subpages/functions';
import OopClasses from './components/Subpages/oop-classes';
import AjaxFetch from './components/Subpages/ajax-fetch';
import AjaxJson from './components/Subpages/ajax-json';
import Debugging from './components/Subpages/debugging';

function App() {
  return (
      <Router>
        <Switch>
          <Route path="/adding-scripts"><Content text={<AddingScripts/>}/></Route>
          <Route path="/comments"><Content text={<Comments/>}/></Route>
          <Route path="/target-dom-elements"><Content text={<TargetDomElements/>}/></Route>
          <Route path="/output"><Content text={<Output/>}/></Route>
          <Route path="/modify-dom-elements"><Content text={<ModifyDomElements/>}/></Route>
          <Route path="/good-practices"><Content text={<GoodPractices/>}/></Route>
          <Route path="/variables"><Content text={<Variables/>}/></Route>
          <Route path="/data-types"><Content text={<DataTypes/>}/></Route>
          <Route path="/operators"><Content text={<Operators/>}/></Route>
          <Route path="/arrays"><Content text={<Arrays/>}/></Route>
          <Route path="/objects"><Content text={<Objects/>}/></Route>
          <Route path="/conditions"><Content text={<Conditions/>}/></Route>
          <Route path="/loops"><Content text={<Loops/>}/></Route>
          <Route path="/functions"><Content text={<Functions/>}/></Route>
          <Route path="/oop-classes"><Content text={<OopClasses/>}/></Route>
          <Route path="/ajax-fetch"><Content text={<AjaxFetch/>}/></Route>
          <Route path="/ajax-json"><Content text={<AjaxJson/>}/></Route>
          <Route path="/debugging"><Content text={<Debugging/>}/></Route>
          <Route path="" exact><Content text={<Home/>}/></Route>
          </Switch>
      </Router>
  );
}

export default App;
