import React from 'react';
import Header from '../Header';
import Code from '../Code';

function AjaxFetch(props) {
  return (
    <div className="section">
      <p>Funkcja <Code language='js' inline code='fetch'/> służy do wysyłania zapytań do serwera. Dzięki niej możemy realizować połączenia AJAX, czyli kontaktować się z serwerem bez przeładowania strony.</p>
        <Header type="h1" text="Metody zapytań"/>
        <p>Istnieją różne metody zapytań, które różnią się swoim przeznaczeniem. API zwykle rozpoznaje metodę zapytania, aby odpowiednio na nie zareagować.</p>
        <table>
          <tr>
            <th>Metoda zapytania</th>
            <th>Przeznaczenie</th>
          </tr>
          <tr>
            <td><Code language='js' inline code='GET'/></td>
            <td>pobieranie danych – <b>domyślna metoda</b></td>
          </tr>
          <tr>
            <td><Code language='js' inline code='POST'/></td>
            <td>tworzenie nowego elementu</td>
          </tr>
          <tr>
            <td><Code language='js' inline code='PUT'/></td>
            <td>nadpisanie elementu</td>
          </tr>
          <tr>
            <td><Code language='js' inline code='PATCH'/></td>
            <td>nadpisanie wybranych właściwości elementu</td>
          </tr>
          <tr>
            <td><Code language='js' inline code='DELETE'/></td>
            <td>usuwanie elementu</td>
          </tr>
        </table>
        <Header type="h1" text="Wysyłanie zapytania"/>
        <p>Wykorzystując domyślne parametry zapytania, możemy użyć funkcji <Code inline language='js' code='fetch'/> podając wyłącznie adres, z którego mają być pobrane dane.</p>
        <Code language="js" code={`
        const url = 'https://reqres.in/api/users';

        const request = fetch(url);
        `}/>
        <p>W stałej <Code inline language='js' code='request'/> znajdzie się tzw. Promise (obietnica). Nie zagłębiając się za bardzo w temat Promise, wyjaśnimy tylko, że jest to obiekt służący do obsługi kodu asynchronicznego. W naszym przypadku posłuży nam do "podłączenia" funkcji, która wykona się po otrzymaniu odpowiedzi z serwera.</p>
        <Header type="h1" text="Odczytywanie odpowiedzi serwera"/>
        <p>Kiedy serwer odpowie na nasze zapytanie, najczęściej będziemy chcieli odczytać jego odpowiedź. Możemy to zrobić za pomocą funkcji przekazanej metodzie <Code inline language='js' code='then'/>, wykonanej na zapytaniu <Code inline language='js' code='request'/>. W tym celu, kontynuując poprzedni przykład, możemy zareagować na zapytanie.</p>
        <p>Należy pamiętać, że odpowiedź jaką otrzymamy jest obiektem typu <Code inline language='js' code='Response'/>, którego nie możemy wprost odczytać. Należy na niej wykonać metodę <Code inline language='js' code='text'/> lub <Code inline language='js' code='json'/>, aby uzyskać wartość gotową do odczytu. Te metody również zwracają Promise, więc ponownie musimy użyć metody <Code inline language='js' code='then'/>, aby odczytać sparsowaną odpowiedź serwera.</p>
        <Code language='js' code={`
        const parseServerResponse = request.then(function(rawResponse){
          return rawResponse.json();
        })
        
        parseServerResponse.then(function(parsedResponse){
          console.log(parsedResponse)
        });
        `}/>
        <Header type="h1" text="Skrótowy zapis fetch"/>
        <p>W praktyce, developerzy raczej nie stosują powyższego zapisu, w którym zachowaliśmy każdy Promise do osobnej stałej/zmiennej. Zamiast tego, stosuje się tzw. chaining, czyli łączenie kilku metod ze sobą za pomocą kropki. Dla zwiększenia czytelności, wszystkie metody mają większe wcięcie w kodzie.</p>
        <Code language='js' code={`
        const url = 'https://reqres.in/api/users';

        fetch(url)
          .then(function(rawResponse){
            return rawResponse.json();
          })
          .then(function(parsedResponse){
            console.log(parsedResponse)
          });
        `}/>
        <p>Możesz powyższy przykład wkleić do konsoli w narzędziach developerskich, aby zobaczyć co zostanie wyświetlone przez <Code language='js' inline code="console.log"/>. Używamy tutaj REQ RES, które podaje zmyślone dane użytkowników. Służy ono właśnie do tego celu, w jakim z niego korzystamy – do testów i nauki.</p>
        <p>Używając tzw. funkcji strzałkowych możemy skrócić powyższy przykład jeszcze bardziej:</p>
        <Code language='js' code={`
        const url = 'https://reqres.in/api/users';

        fetch(url)
          .then(rawResponse => rawResponse.json())
          .then(parsedResponse => {
            console.log(parsedResponse)
          });
        `}/>
        <Header type="h1" text="Opcje fetch"/>
        <p>Oprócz domyślnego zapytania metodą <Code language='js' inline code='GET'/>, fetch umożliwia również wysyłanie innych rodzajów zapytań, a także zmiany ich nagłówków. Może to być potrzebne do poprawnej komunikacji z API.</p>
        <Code language='js' code={`
        const url = 'https://reqres.in/api/users';

        const payload = {
          name: 'Mack Tubby', 
          job: 'Top Cat',
        };
        
        const options = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        };
        
        fetch(url, options)
          .then(rawResponse => rawResponse.json())
          .then(parsedResponse => {
            console.log(parsedResponse)
          });
        `}/>
        <p>Obiekt <Code inline language='js' code='payload'/> (ładunek) zawiera dane, które chcemy wysłać do serwera. Obiekt <Code inline language='js' code='options'/> zawiera informacje:</p>
        <ul>
          <li>o użyciu metody <Code inline language='js' code='POST'/>,</li>
          <li>o dodaniu do nagłówków zapytania informacji, że będziemy wysyłać dane w formacie JSON,</li>
          <li>dane, które chcemy wysłać, sformatowane jako JSON.</li>
        </ul>
        <p>Serwer, po otrzymaniu tego zapytania, zapisze te informacje jako nowego użytkownika, dodając do przesłanych informacji <Code language='js' inline code='id'/> oraz datę utworzenia.</p>
        <Header type="h1" text="Wychwytywanie błędów połączenia"/>
        <p>Do <Code language='js' inline code='fetch'/> możemy dodać również metodę <Code language='js' inline code='catch'/>, która wykona przekazaną jej funkcję w przypadku, kiedy nastąpi błąd połączenia – np. kiedy wpiszemy nieistniejącą domenę, lub nasz komputer straci połączenie z internetem.</p>
        <Code language='js' code={`
        const urlWithBadDomain = 'https://not-really-a-website.nope/api/users';

        fetch(urlWithBadDomain)
          .then(rawResponse => rawResponse.json())
          .then(parsedResponse => {
            console.log(parsedResponse)
          })
          .catch((error) => {
            console.warn('CONNECTION ERROR', error)
          });
        `}/>
        <p>To jednak nie wystarczy, aby poprawnie obsłużyć błędy, kiedy połączenie zostało nawiązane, ale serwer zwrócił np. kod <Code language='js' inline code='404'/> (nie znaleziono strony). W tym celu musimy użyć nieco bardziej skomplikowanego rozwiązania.</p>
        <Code language='js' code={`
        const urlWithBadDomain = 'https://reqres.in/wrong/page';

        fetch(urlWithBadDomain)
          .then(rawResponse => {
            if (rawResponse.status >= 200 && rawResponse.status < 300) {
              return rawResponse.json();
            } else {
              return Promise.reject(rawResponse.status + ' ' + rawResponse.statusText);
            }
          })
          .then(parsedResponse => {
            console.log(parsedResponse)
          })
          .catch((error)=>{
            console.log('CONNECTION ERROR', error)
          });
        `}/>
        <p>Taki zapis obsługuje wszystkie <a className="exampleLink" href="https://pl.wikipedia.org/wiki/Kod_odpowiedzi_HTTP" target="_blank" rel="noreferrer">kody odpowiedzi HTTP</a>, które informują o jakimkolwiek błędzie.</p>
    </div>
  );
} 

export default AjaxFetch;
