import React from 'react';
import Header from '../Header';
import Code from '../Code';

function Loops(props) {
  return (
    <div className="section">
      <p>Pętle (loops) pozwalają na wykonywanie tych samych czynności w kółko określoną liczbę razy, albo dla określonego zestawu elementów.</p>
      <Header type="h1" text="Pętla for"/>
      <p>Pętla <Code language='js' inline code="for"/> pozwala wykonywać fragment kodu wiele razy, np. 5 razy lub tyle razy, ile jest elementów w tablicy.</p>
      <Header type="h5" text="Przykład 1"/>
      <Code language='js' code={
      `
      for( let i = 0; i < 3; i++ ){
        console.log('Loop iteration: ' + i);
      }
      `
      }/>
      <p>Powyższa pętla wykona się 3 razy i tyle razy wyświetli w konsoli zdefiniowany tekst.</p>
      <Header type="h5" text="Przykład 2"/>
      <Code language='js' code={
      `
      const elementsList = ['lorem', 123, 3+'c', '999'];

      for( let i = 0; i < elementsList.length; i++ ){
        if(typeof(elementsList[i]) == 'string'){
          console.log('Text from an element from elementsList at index ' + i + ' is: ' + elementsList[i]);
        }
      } 
      `
      }/>
      <p>Powyższa pętla wykona się dla każdego elementu w tablicy <Code language='js' inline code='elementsList'/> i wyświetli w konsoli wartość tego elementu.</p>
      <Header type="h1" text="Pętla for...in"/>
      <p>Pętla <Code inline language='js' code='for...in'/> umożliwia wykonywanie operacji dla każdego elementu w obiekcie.</p>
      <Header type="h5" text="Przykład"/>
      <Code language='js' code={
      `
      const person = {
        name: 'John',
        surname: 'Doe',
        age: 35
      };
      
      for( let key in person ){
        console.log('Value at key "' + key + '" in person: ' + person[key]);
      }
      `
      }/>
      <p>Powyższa pętla wyświetli w konsoli wartości kolejnych elementów obiektu <Code inline language='js' code='person'/>.</p>
      <Header type="h1" text="Pętla for...of"/>
      <p>Pętla <Code inline language='js' code='for...of'/> umożliwia wykonywanie operacji na obiektach iterowalnych, takich jak tablice lub mapy.</p>
      <Header type="h5" text="Przykład"/>
      <Code language='js' code={
        `
      const animals = ['Cat', 'Dog', 'Mouse', 'Bird'];

      for( let animal of animals ){
        console.log(animal);
      }
        `
      }/>
      <p>Powyższa pętla wyświetli w konsoli każdy element zawarty w tablicy <Code inline language='js' code='animals'/>.</p>
      <Header type="h1" text="Pętla while"/>
      <p>Pętla <Code language='js' inline code='while'/> za każdą iteracją sprawdza zdefiniowany warunek i wykonuje żądany blok kodu tak długo, jak długo warunek jest prawdziwy.</p>
      <Header type="h5" text="Przykład 1"/>
      <Code language='js' code={`
      let i = 0;

      while (i < 10) {
       console.log(i);
       i++;
      }
      `}/>
      <p>Powyższa pętla będzie iterować tylko tak długo, jak długo <Code language='js' code='i'/> będzie mniejsze niż 10. W rezultacie wyświetli w konsoli liczby od 0 do 9.</p>
      <Header type="h5" text="Przykład 2"/>
      <Code language='js' code={`
      let i = 15;

      while (i < 10) {
        console.log(i);
        i++;
      }
      `}/>
      <p>W powyższej pętli warunek od razu zostanie uznany za fałszywy, więc pętla nie zwróci wyniku.</p>
      <Header type="h1" text="Pętla do...while"/>
      <p>Pętla <Code language='js' inline code='do...while'/> jest podobna do powyższej – będzie się wykonywać tak długo, jak długo zdefinowany warunek będzie spełniony. Różnica jest taka, że pętla <Code language='js' inline code='do...while'/> zawsze wykona się przynajmniej raz, ponieważ warunek (<Code language='js' inline code='while'/>) jest sprawdzany po wykonaniu instrukcji (<Code language='js' inline code='do'/>).</p>
      <Header type="h5" text="Przykład 1"/>
      <Code language='js' code={`
      let i = 0;

      do {
        console.log(i);
        i++;
      } while (i < 10);
      `}/>
      <p>Powyższa pętla będzie iterować tylko tak długo, jak długo <Code language='js' inline code='i'/> będzie mniejsze niż 10. W rezultacie wyświetli w konsoli liczby od 0 do 9.</p>
      <Header type="h5" text="Przykład 2"/>
      <Code language='js' code={`
      let i = 15;

      do {
        console.log(i);
        i++;
      } while (i < 10);
      `}/>
      <p>Powyższa pętla wykona jedną iterację i wyświetli w konsoli wynik (wartość zmiennej <Code inline language='js' code='i'/>). Następnie przerwie działanie.</p>
    </div>
  );
} 

export default Loops;
