import React from 'react';
import Header from '../Header';
import Code from '../Code';
import {Box} from '@material-ui/core'

function Debugging(props) {
  return (
    <div className="section">
      <p>Jednym z filarów programowania jest umiejętność wyszukiwania i naprawiania błędów.</p>
        <Header type="h1" text="Google Developer Tools"/>
        <p>Warto poznać to narzędze, żeby uniknąć podstawowych bolączek młodego developera. W przeglądarce zaczynamy od uruchomienia konsoli, co możemy zrobić na dwa sposoby:</p>
        <ul>
          <li>klikając PPM (prawy przycisk myszy), a następnie wybierając opcję "Zbadaj" (ang. Inspect),</li>
          <li>używając klawiaturowego skrótu – Ctrl+Shift+C w przypadku Windowsa i Linuksa oraz Command+Option+C, jeżeli pracujemy na Macu.</li>
        </ul>
        <img width="95%" alt="debugging" src="debugging1.png"/>
        <p>Po uruchomieniu narzędzi developerskich zobaczymy zbiór wielu zakładek, następnie w głównej części strony tzw. DOM, czyli "drzewo" dokumentu oraz kolumnę, która wyświetla style aktualnie wybranego elementu.</p>
        <p>Zarówno wygenerowany kod HTML, jak i style poszczególnych części markupu (struktury strony) możemy edytować na żywo, śledząc na bieżąco zmiany, które pojawiają się na stronie.</p>
        <Header type="h1" text="Debuggowanie kodu JS w konsoli"/>
        <p>Jednym z podstawowych narzędzi pomagających w wykryciu błędów jest konsola w narzędziach developerskich przeglądarki i obiekt <Code language='js' inline code='console'/> w naszym kodzie. Nie ogranicza się on jedynie do metody <Code language='js' inline code='log()'/> w celu wyświetlania informacji. Wśród pozostałych metod możemy bowiem znaleźć (między innymi):</p>
        <ul>
          <li><Box py={3}><Code inline language='js' code='console.error(message);'/>- który wyświetli nam stringa wraz z przekazanymi argumentami w bardziej widocznym i znanym z JS-owych błędów formatowaniu:</Box>
          <img width="55%" alt="debugging" src="debugging2.png"/>
          </li>
          <li><Box py={3}><Code inline language='js' code='console.assert(assertion, obj1 [, obj2, ..., objN]);'/>  - pozwalające na przekazanie wiadomości do konsoli, tylko jeżeli warunek nie zostanie spełniony:</Box>
          <img width="70%" alt="debugging" src="debugging3.png"/>
          </li>
          <li><Box py={3}><Code inline language='js' code='console.time(timerName);'/> oraz <Code inline language='js' code='console.timeEnd(timerName)'/> - współdziałające ze sobą metody, które w milisekundach obliczą czas od uruchomienia "stopera" o danej nazwie do jego zatrzymania:</Box>
          <img width="35%" alt="debugging" src="debugging4.png"/>
          </li>
          <li><Box py={3}><Code inline language='js' code='console.trace()'/> - jedna z ciekawszych metod, zwracająca ścieżkę sprawdzanego stosu z danej lokalizacji:</Box>
          <img width="45%" alt="debugging" src="debugging5.png"/>
          </li>
        </ul>
        <p>Nie oznacza to jednak, że musimy umyślnie komplikować proces wyświetlania danych, ponieważ w przeważającej liczbie przypadków <Code language='js' inline code='console.log()'/> w zupełności nam wystarczy. Pamiętajmy jedynie, że możemy w pełni wykorzystać jego potencjał i jako argumenty przekazywać informacje w postaci tablic, obiektów czy nawet danych sformatowanych przy pomocy JS-owych metod!</p>
        <Header type="h1" text="Linia po linii"/>
        <p>Powyższe rozwiązania i możliwości dostarczają nam potrzebnych informacji, nie przerywając działania naszego kodu. Zastanówmy się jednak co zrobić, jeżeli chcielibyśmy zatrzymać się w połowie naszej funkcji, linia po linii przeanalizować to, co się tam dzieje i „na żywo” podejrzeć poszczególne zmienne? Jak się pewnie domyślasz, JavaScript oferuje nam taką możliwość za pomocą komendy debugger, której przykład użycia możesz prześledzić w poniższym kodzie (uprzednio uruchamiając konsolę):</p>
        <ul><li><a className="exampleLink" href="https://codepen.io/kodilla/pen/f24e6a89c29b6f5d83d0886f214de08e/" target="_blank" without rel="noreferrer">Przykład</a></li></ul>
        <p>Jak widzisz, nasz kod zatrzyma się dokładnie w tym miejscu, w którym użyliśmy słowa kluczowego <Code inline language='js' code='debugger'/> i pozwoli nam przyjrzeć się wszystkim informacjom, do jakich mamy dostęp w obrębie funkcji <Code inline language='js' code='displayPersonDetails'/>(). Możemy zatem sprawdzić wartości, jakie przyjmują wszystkie argumenty oraz zmienne, których instrukcje zdążyły się wykonać do momentu zatrzymania wykonywania kodu.</p>
        <img width="80%" alt="debugging" src="debugging6.png"/>
        <p>My jednak jesteśmy ciekawi wartości, jaką przyjmuje zmienna <Code language='js' inline code='details'/>, dlatego pozwolimy silnikowi JavaScriptu wykonać kilka kolejnych instrukcji przy pomocy przycisku znajdującego się po prawej stronie narzędzi developerskich, a dokładniej strzałki Step over next function call:</p>
        <img width="50%" alt="debugging" src="debugging7.jpg"/>
        <p>Jak zauważysz, każde kliknięcie przeniesie Cię do kolejnej instrukcji i będziesz w stanie podejrzeć kolejno wartość <Code language='js' inline code='personName'/>, <Code language='js' inline code='birthYear'/>, a następnie <Code language='js' inline code='details'/>! Bardzo często zdarzy się jednak, że informacja, której potrzebujemy, znajduje się wiele linii kodu dalej lub nawet w innej funkcji i zamiast przeskakiwania po kolejnych instrukcjach, chcemy przejść od razu do kolejnego debuggera, a to z kolei umożliwia nam znajdująca się po lewej stronie od poprzedniego przycisku niebieska strzałka z opisem Resume scripts execution.</p>
        <img width="45%" alt="debugging" src="debugging8.jpg"/>
        <p>Oznacza to, że po jej kliknięciu skrypt wznawia swoje działanie i zatrzyma się dopiero, jeżeli natrafi na kolejny debugger, bądź tzw. breakpoint.</p>
        <p>Breakpoint to kolejna funkcjonalność narzędzi developerskich, która pozwoli Ci, podobnie jak w przypadku debuggera, wstrzymać działanie kodu, jednak tym razem zmianę wprowadzimy jedynie po stronie przeglądarki, a nasz kod pozostanie nietknięty. Oznaczenie takiego punktu jest bardzo proste i polega na kliknięciu numeru linii, w której chcemy się zatrzymać:</p>
        <img width="95%" alt="debugging" src="debugging9.png"/>
        <p>Numer zaznaczonej linii podświetli się na kolor zielony lub niebieski (w zależności od używanej ciemnej lub jasnej wersji szablonu narzędzi developerskich), a my będziemy wtedy mogli przywrócić wykonywanie kodu, mając pewność, że zatrzyma się on dopiero w linii 8, przed wykonaniem instrukcji <Code language='js' inline code='return details;'/>.</p>
    </div>
  );
} 

export default Debugging;
