import React from 'react';
import Header from '../Header';
import Code from '../Code';

function Variables(props) {

    const let_example = `
    function getDifference(a, b) {
        let result = a - b;
    
        if(result < 0){
            result = result * -1;
        }
    
        return result;
    }
    
    console.log( getDifference(5, 7) );
    `;
    const const_example = `
    const name = 'John';

    // this would be an error:
    // name = 'Bob';
    `;
    const const_example2 = `
    const names = ['John', 'Mary', 'Jane'];
    names.push('Adam');
    
    console.log(names);
    `

  return (
    <div className="section">
        <p>Zmienne i stałe to "kontenery", w których przechowujemy pewne informacje lub wartości. Możemy później odwoływać się do tych wartości w kodzie, podając tylko nazwę zmiennej/stałej.</p>
        <Header type="h1" text="Zmienna let"/>
        <p>Zmienna <Code language='js' inline code="let"/> służy do przechowywania wartości, które mogą być modyfikowane w kodzie. Zakres zmiennej <Code language='js' inline code='let'/> to najbliższy blok kodu, zamknięty w nawiasy klamrowe <Code language='js' inline code='{ }'/></p>
        <Header type="h5" text="Przykład"/>
        <Code language='js' code={let_example}/>
        <p>W powyższym przykładzie zmienna <Code language='js' inline code="result"/> będzie istniała tylko wewnątrz funkcji <Code language='js' inline code='getDifference'/>. Próba użycia jej poza tą funkcją wywoła błąd.</p>
        <Header type="h1" text="Stała const"/>
        <p>Stałych <Code language='js' inline code="const"/> używamy dokładnie tak samo, jak zmiennych <Code language='js' inline code="let"/>. Jedyna różnica polega na tym, że do stałej możemy (i musimy) przypisać wartość tylko i wyłącznie w momencie jej deklaracji. Próba ponownego przypisania wartości spowoduje błąd skryptu.</p>
        <Code language='js' code={const_example}/>
        <p>W przypadku użycia stałej <Code language='js' inline code="const"/>, jeżeli jej wartość jest tablicą bądź obiektem, możemy zmieniać ich zawartość.</p>
        <Header type="h5" text="Przykład"/>
        <Code language='js' code={const_example2}/>
        <p>W powyższym przykładzie wartość stałej nie zmieniła się – jest nią cały czas tablica names. Zmieniliśmy natomiast zawartość samej tablicy, dodając do niej kolejny element, i nie spowodowało to błędu w kodzie.</p>
    </div>
  );
} 

export default Variables;
