import React from 'react';
import Header from '../Header';
import Code from '../Code';

function AddingScripts(props) {
    const code = `
<script>

// scripts go here

</script>
`;

  return (
    <div className="section">
        <Header type="h1" text="Wprowadzenie"/>
        <p>Istnieją dwie podstawowe metody dodawania skryptów JS na stronie.</p>
        <Header type="h1" text="Zewnętrzny plik"/>
        <p>Aby podlinkować zewnętrzny plik skryptów na stronie, umieść poniższy tag (z odpowiednią ścieżką w <Code inline language="markup" code="src"/>) przed tagiem <Code inline language="html" code="</body>"/>:</p>
        <Code language="javascript" code='<script src="script.js"></script>'></Code>
        <p>Jest to standardowa i najbardziej polecana metoda.</p>
        <Header type="h1" text="W kodzie HTML"/>
        <p>Można również umieszczać skrypty bezpośrednio w kodzie HTML, owijając je w tag <Code inline language="html" code="<script>" />:</p>
        <Code language="javascript" code={code}/>
        <p>Tej metody należy używać tylko w szczególnych przypadkach, kiedy nie ma możliwości użycia zewnętrznego pliku.</p>
    </div>
  );
} 

export default AddingScripts;
