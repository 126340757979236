import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appColor: {
    backgroundColor: '#358622'
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function Content(props) {
  const { window } = props;
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <List>
        <ListItem><b>Podstawy JavaScriptu</b></ListItem>
        <Link className="menuLink" to="/adding-scripts"><ListItem button disableGutters><ListItemText primary={"Dodawanie skryptów na stronie"} /></ListItem></Link>
        <Link className="menuLink" to="/comments"><ListItem button disableGutters><ListItemText primary={"Komentarze"} /></ListItem></Link>
        <Link className="menuLink" to="/output"><ListItem button disableGutters><ListItemText primary={"Wyświetlanie treści"} /></ListItem></Link>
        <Link className="menuLink" to="/target-dom-elements"><ListItem button disableGutters><ListItemText primary={"Znajdowanie elementów DOM"} /></ListItem></Link>
        <Link className="menuLink" to="/modify-dom-elements"><ListItem button disableGutters><ListItemText primary={"Manipulacja elementami DOM"} /></ListItem></Link>
        <Link className="menuLink" to="/good-practices"><ListItem button disableGutters><ListItemText primary={"Dobre praktyki"} /> </ListItem></Link>
        <ListItem><b>Przechowywanie i zmiana wartości</b></ListItem>
        <Link className="menuLink" to="/variables"><ListItem button disableGutters><ListItemText primary={"Zmienne i stałe"} /> </ListItem></Link>
        <Link className="menuLink" to="/data-types"><ListItem button disableGutters><ListItemText primary={"Typy danych"} /> </ListItem></Link>
        <Link className="menuLink" to="/operators"><ListItem button disableGutters><ListItemText primary={"Operatory i działania"} /> </ListItem></Link>
        <Link className="menuLink" to="/arrays"><ListItem button disableGutters><ListItemText primary={"Tablice"} /></ListItem></Link>
        <Link className="menuLink" to="/objects"><ListItem button disableGutters><ListItemText primary={"Obiekty"} /> </ListItem></Link>
        <ListItem><b>Wyrażenia</b></ListItem>
        <Link className="menuLink" to="/conditions"><ListItem button disableGutters><ListItemText primary={"Warunki"} /> </ListItem></Link>
        <Link className="menuLink" to="/loops"><ListItem button disableGutters><ListItemText primary={"Pętle"} /></ListItem></Link>
        <Link className="menuLink" to="/functions"><ListItem button disableGutters><ListItemText primary={"Funkcje"} /></ListItem></Link>
        <ListItem><b>Programowanie obiektowe (OOP)</b></ListItem>
        <Link className="menuLink" to="/oop-classes"><ListItem button disableGutters><ListItemText primary={"Klasy i instancje"} /> </ListItem></Link>
        <ListItem><b>AJAX</b></ListItem>
        <Link className="menuLink" to="/ajax-fetch"><ListItem button disableGutters><ListItemText primary={"Fetch"} /> </ListItem></Link>
        <Link className="menuLink" to="/ajax-json"><ListItem button disableGutters><ListItemText primary={"Biblioteka JSON"} /> </ListItem></Link>
        <ListItem><b>Debuggowanie</b></ListItem>
        <Link className="menuLink" to="/debugging"><ListItem button disableGutters><ListItemText primary={"Błędy"} /></ListItem></Link>
      </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" classes={{ colorPrimary: classes.appColor }} className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon/>
          </IconButton>
          <Typography variant="h6" noWrap>
            Kodilla - Poradnik JS
          </Typography>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            anchor='left'
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{ paper: classes.drawerPaper, }}
            ModalProps={{ keepMounted: true, }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {props.text}
      </main>
    </div>
  );
}


export default Content;
