
import React from 'react';
import Header from '../Header';

function Home(props) {
  return (
    <div className="section">
        <Header type="h1" text="Poradnik JavaScript (ES6)"/>
        <p>Witaj</p>
        <p>Materiały na tej stronie stanowią uzupełnienie bootcampa <b>Front-end: React</b> w Kodilli.</p>
        <p>Pozwolą Ci one utrwalić zdobytą wiedzę z zakresu JavaScriptu (ES6), a także poznać kilka właściwości, które nie były wspomniane podczas kursu.</p>
    </div>
  );
} 

export default Home;
