import React from 'react';
import '../App.css';
import Highlight, { defaultProps } from "prism-react-renderer";
import theme from 'prism-react-renderer/themes/duotoneLight'

function Code(props) {

    const codeBlock = () => {
        return props.inline ? 
        
        <Highlight {...defaultProps} theme={theme} code={props.code} language={props.language}>
            {({ className, style, tokens, getLineProps, getTokenProps }) => (
                <pre className={props.inline ? (className + " inline") : ""} style={style}>
                    {tokens.map((line, i) => (
                        <span {...getLineProps({ line, key: i })}>
                            {line.map((token, key) => (
                                <span {...getTokenProps({ token, key })} />
                            ))}
                        </span>
                    ))}
                </pre>
            )}
        </Highlight>
        : 
        <Highlight {...defaultProps} theme={theme} code={props.code} language={props.language}>
            {({ className, style, tokens, getLineProps, getTokenProps }) => (
                <pre className={className} style={style}>
                    {tokens.map((line, i) => (
                        <div {...getLineProps({ line, key: i })}>
                            {line.map((token, key) => (
                                <span {...getTokenProps({ token, key })} />
                            ))}
                        </div>
                    ))}
                </pre>
            )}
        </Highlight>
    }
    

    return (
    
            props.inline ? <span className="codeBox">{codeBlock()}</span> : <div className="codeBox">{codeBlock()}</div>
    ) 
}

export default Code;
