import React from 'react';
import Header from '../Header';
import Code from '../Code';

function Functions(props) {
  return (
    <div className="section">
        <p>Funkcje pozwalają na wydzielenie fragmentu kodu, który może być wykonywany wielokrotnie. Każda funkcja może:</p>
        <ul>
            <li>przyjmować argumenty,</li>
            <li>wykonywać jakieś operacje,</li>
            <li>zwracać jakąś wartość w miejsce jej wykonania.</li>
        </ul>
        <Header type="h1" text="Deklaracja funkcji"/>
        <p>Stworzenie funkcji nazywa się jej <b>deklaracją.</b></p>
        <Header type="h5" text="Składnia"/>
        <Code language="js" code={`
        function sumThreeNumbers(firstNumber, secondNumber, thirdNumber){
          const sum = firstNumber + secondNumber + thirdNumber;
          return sum;
        }
        
        function showWarning(message){
          alert('Warning! ' + message);
        }
        `}/>
        <p>Wyjaśnienie składni:</p>
        <ul>
            <li><Code language='js' inline code='exampleFunction'/> jest nazwą funkcji,</li>
            <li>nawiasy okrągłe <Code language='js' inline code='() )'/> okalają listę argumentów (obowiązkowe, nawet jeśli nie nazywamy żadnego z argumentów),</li>
            <li><Code language='js' inline code='firstNumber'/> jest nazwą pierwszego argumentu funkcji,</li>
            <li>analogicznie, <Code language='js' inline code='secondNumber'/> i <Code language='js' inline code='thirdNumber'/> są kolejnymi argumentami funkcji,</li>
            <li>nawiasy klamrowe <Code language='js' inline code='{ }'/> okalają kod wykonywany przez funkcję</li>
            <li>wartość po słowie <Code language='js' inline code='return'/> zostanie zwrócona przez funkcję.</li>
            <li>po deklaracji funkcji nie piszemy średnika.</li>
        </ul>

        <Header type="h1" text="Wywołanie funkcji"/>
        <p>Deklaracja funkcji nie wykonuje żadnych operacji – dopiero po wywołaniu funkcji zostanie wykonany zawarty w niej kod. Wywołując funkcję, możemy przekazać jej taką liczbę argumentów, jakiej spodziewa się funkcja.</p>
        <Code language='js' code={`
        const sumOfNumbers = sumThreeNumbers(7, 14, 21);
        console.log('sumOfNumbers:', sumOfNumbers); // sumOfNumbers: 42
        `}/>
        <p>Nie każda funkcja będzie zwracała jakiekolwiek dane, lub zwracane przez nią wartości mogą nie być dla nas przydatne. W takim przypadku nie musimy zapisywać (ani w inny sposób wykorzystywać) wartości zwracanych przez funkcję.</p>
        <Code language='js' code={`
        showWarning('This site uses cookies!');
        `}/>
        <Header type="h1" text="Domyślne wartości argumentów"/>
        <p>Czasami możemy potrzebować funkcji, która będzie miała domyślne wartości argumentów. Pozwoli nam to przeważnie wywoływać funkcję bez argumentów (lub z mniejszą ich liczbą), a tylko czasami podawać wszystkie argumenty.</p>
        <p>Weźmy na przykład funkcję, która liczy marżę doliczaną do ceny produktu. Domyślnie marża ma wynosić <Code inline language='js' code='10%'/>, ale w poszczególnych przypadkach może być większa lub mniejsza.</p>
        <Code language='js' code={
        `
        function addProfitMargin(price, profitMargin = 0.1){
            return price + price * profitMargin;
        }
            
            const applePrice = addProfitMargin(1);
            const milkPrice = addProfitMargin(5, 0.05);
            const chocolatePrice = addProfitMargin(10, 0.3);
            
            console.log('applePrice:', applePrice); // applePrice: 1.1
            console.log('milkPrice:', milkPrice); // milkPrice: 5.25
            console.log('chocolatePrice:', chocolatePrice); // chocolatePrice: 13
        `
        }/>
        <p>Pamiętaj, że argumenty są podawane po kolei, począwszy od pierwszego. W związku z tym zwykle nie ma sensu podanie domyślnej wartości np. dla pierwszego argumentu, podczas gdy któryś z kolejnych argumentów nie ma domyślnej wartości.</p>
        <Header type="h1" text="Funkcje anonimowe"/>
        <p>Do tej pory prezentowaliśmy przykłady funkcji nazwanych – w składni deklaracji funkcji występowała jej nazwa. Często w kodzie JS zdarzają się sytuacje, w których nie mamy potrzeby nazwania funkcji, ponieważ użyjemy jej tylko raz. Świetnym przykładem może być handler eventu.</p>
        <p>Zobaczmy najpierw przykład handlera eventu zadeklarowanego jako funkcja nazwana:</p>
        <Code language='js' code={`
        const menuLink = document.querySelector('.menu-link');

        function menuClickHandler(event){
          event.preventDefault();
          event.currentTarget.classList.toggle('active');
        }
        
        menuLink.addEventListener('click', menuClickHandler);
        `}/>
        <p>Wykorzystując funkcję anonimową możemy zastosować bardziej zwięzłą formę:</p>
        <Code language='js' code={`
        const menuLink = document.querySelector('.menu-link');

        menuLink.addEventListener('click', function(event){
          event.preventDefault();
          event.currentTarget.classList.toggle('active');
        });
        `}/>
        <p>Innym przykładem zastosowania funkcji anonimowych może być potraktowanie funkcji jako wartości, i przypisanie jej do zmiennej, stałej, właściwości obiektu, lub jako element tablicy. Zobaczmy, jak wyglądałby przykład przechowywania paru funkcji w obiekcie.</p>
        <Code language='js' code={`
        const app = {};

        app.init = function(){
          app.initMenuLink();
          app.cookieMessageTimeout();
        };
        
        app.initMenuLink = function(){
          const menuLink = document.querySelector('.menu-link');
        
          menuLink.addEventListener('click', function(event){
            event.preventDefault();
            event.currentTarget.classList.toggle('active');
          });
        };
        
        app.cookieMessageTimeout = function(){
          const cookieMessage = document.querySelector('.cookie-message');
        
          setTimeout(function(){
            cookieMessage.classList.remove('visible');
          }, 5000);
        };
        
        app.init();
        `}/>
        <p>W ten sposób uporządkowaliśmy funkcje inicjujące działanie strony w jednym obiekcie <Code inline language='js' code='app'/>. Zwróć uwagę, że dopiero ostatnia linia powyższego przykładu – <Code inline language='js' code='app.init();'/> – jest wywołaniem funkcji. Z kolei funkcja zapisana we właściwości <Code inline language='js' code='app.init'/> wykonuje pozostałe dwie funkcje.</p>
        <Header type="h1" text="Funkcje strzałkowe"/>
        <p>Kolejnym uproszczeniem składni JS są funkcje strzałkowe. Są one bardzo podobne do funkcji anonimowych, z dwiema kluczowymi różnicami. Zacznijmy jednak od najprostszej formy – porównajmy cztery przykłady wykorzystania metody <Code inline language='js' code='filter'/> na tablicach. Każdy z nich zadziała tak samo i różni się tylko zastosowaną składnią.</p>
        <Code language='js' code={`
        const numbers = [1, 3, 4, 7, 8, 13, 16, 20];

        // EXAMPLE 1
        
        function filteringFunction(item){
          return item < 5;
        };
        
        const smallNumbers1 = numbers.filter(filteringFunction);
        
        console.log('smallNumbers1:', smallNumbers1); // smallNumbers1: [1, 3, 4]
        
        // EXAMPLE 2
        
        const smallNumbers2 = numbers.filter(function(item){
          return item < 5;
        });
        
        console.log('smallNumbers2:', smallNumbers2); // smallNumbers2: [1, 3, 4]
        
        // EXAMPLE 3
        
        const smallNumbers3 = numbers.filter((item) => {
          return item < 5;
        });
        
        console.log('smallNumbers3:', smallNumbers3); // smallNumbers3: [1, 3, 4]
        
        // EXAMPLE 4
        
        const smallNumbers4 = numbers.filter(item => item < 5);
        
        console.log('smallNumbers4:', smallNumbers4); // smallNumbers4: [1, 3, 4]
        `}/>
        <p>W powyższych przykładach użyliśmy:</p>
        <ul>
            <li>przykład 1. – funkcja nazwana,</li>
            <li>przykład 2. – funkcja anonimowa,</li>
            <li>przykład 3. – funkcja strzałkowa z blokiem operacji,</li>
            <li>przykład 4. – funkcja strzałkowa zwracająca wartość.</li>
        </ul>
        <p>Jak widzisz, przykłady 2. i 3. tylko nieznacznie się od siebie różnią. Pozbyliśmy się słowa <Code language='js' inline code='function'/>, a dodaliśmy operator <Code language='js' inline code='=>'/> pomiędzy listą argumentów, a blokiem operacji wykonywanych przez funkcję. Jest to jak najbardziej poprawne zastosowanie funkcji strzałkowej. Używa się go jednak głównie w sytuacjach, kiedy funkcja ma wykonywać jakieś operacje inne niż samo zwrócenie wartości.</p>
        <p>Dlatego w tym zastosowaniu najczęściej używa się składni zaprezentowanej w przykładzie 4. Charakteryzuje ją:</p>
        <ul>
            <li>brak nawiasów okrągłych, które można pominąć, jeśli deklarujemy tylko i dokładnie jeden argument,</li>
            <li>brak nawiasów klamrowych, których pominięcie oznacza, że wynik operacji po <Code inline language='js' code='=>'/> ma zostać zwrócony przez tę funkcję.</li>
        </ul>
        <p>Funkcje strzałkowe, podobnie jak anonimowe, również można zapisywać w zmiennych (lub stałych, obiektach i tablicach) oraz wykorzystywać domyślne wartości argumentów.</p>
        <p>Spójrz, jak możemy zapisać funkcję <Code inline language='js' code='addProfitMargin'/> z wcześniejszego przykładu dotyczącego domyślnych wartości argumentów.</p>
        <Code language='js' code={`
        const addProfitMargin = (price, profitMargin = 0.1) => price + price * profitMargin;

        console.log('10 * 1.1', addProfitMargin(10)); // 11
        console.log('10 * 1.5', addProfitMargin(10, 0.5)); // 15
        `}/>
        <p>Tak więc, funkcje strzałkowe od anonimowych odróżnia <b>przede wszystkim składnia</b>. Możemy zastosować zarówno składnię z blokiem operacji <Code language='js' inline code='{ }'/>, jak i skrótową składnię zwracającą wartość po <Code language='js' inline code='=>'/>.</p>
        <p><b>Drugą kluczową różnicą</b> jest znaczenie słowa <Code inline language='js' code='this'/>. Każda funkcja, w tym funkcja anonimowa, zmienia obiekt zapisany w <Code inline language='js' code='this'/>. Funkcja strzałkowa nie zmienia jego znaczenia. Jest to bardzo istotna różnica np. w przypadku handlerów eventów. Jeśli jest nim funkcja nazwana lub anonimowa, słowo <Code inline language='js' code='this'/> będzie w niej oznaczało element, dla którego został dodany ten handler. Jeśli natomiast użyjemy funkcji strzałkowej, słowo <Code inline language='js' code='this'/> w handlerze będzie oznaczało to samo, co poza handlerem.</p>
    </div>

  );
} 

export default Functions;
