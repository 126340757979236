import React from 'react';
import Header from '../Header';
import Code from '../Code';

function Arrays(props) {
  return (
    <div className="section">
      <p>Tablice (arrays) są kontenerami danych, co znaczy, że można w nich umieszczać wiele wartości. Zbiór wartości tablicy nazywamy elementami tablicy.</p>
      <p>Każdy element tablicy ma przypisany indeks, czyli numer reprezentujący jego pozycję w tablicy. Indeksy liczymy od zera, czyli pierwszy element będzie miał indeks <Code language="javascript" inline code='0'/>. Co ważne, jest to aktualna pozycja w tablicy – przy zmianach zawartości tablicy mogą zmieniać się indeksy poszczególnych wartości.</p>
      <p>Tablice stosujemy w sytuacjach, gdy potrzebujemy zbioru wartości, dla których nie potrzebujemy stałych kluczy (etykiet, nazw). Dobrym przykładem będzie każda sytuacja, w której będziemy iterować pętlą przez wszystkie elementy tablicy, ale nigdy (poza pętlą) nie będziemy potrzebowali uzyskać dostępu do pojedynczego elementu tablicy.</p>
      <Header type="h1" text="Tworzenie tablicy"/>
      <p>Tablicę tworzymy za pomocą nawiasów kwadratowych <Code inline language='js' code='[ ]'/>. Możemy stworzyć pustą tablicę, lub od razu zapisać w niej elementy.</p>
      <Code language="javascript" code={
        `
        const categories = [];
        console.log('categories:', categories); // categories: []

        const keywords = ['travel', 'France'];
        console.log('keywords:', keywords); // keywords: ['travel', 'France']
        `
      }/>
      <p>Standardowo tablicę będziemy zapisywać w stałej <Code inline language='js' code='const'/>, aby przez przypadek nie nadpisać całej tablicy. Nie jest to przeszkodą, aby zmieniać zawartość tablicy.</p>
      <Header type="h1" text="Dodawanie elementów do tablicy"/>
      <p>Najczęstszą metodą dodawania wartości do tablicy jest <Code inline language='js' code='push'/>, która dodaje nowe wartości na końcu tablicy.</p>
      <Code language='js' code={
        `
        categories.push('animals');
        console.log('categories:', categories); // categories: ['animals']
        
        categories.push('travel');
        console.log('categories:', categories); // categories: ['animals', 'travel']
        
        keywords.push('Paris');
        console.log('keywords:', keywords); // keywords: ['travel', 'France', 'Paris']
        `
      }/>
      <Header type="h1" text="Liczba elementów w tablicy"/>
      <p>Możemy łatwo sprawdzić, ile elementów znajduje się obecnie w tablicy za pomocą właściwości <Code inline language='js' code='length'/>.</p>
      <Code language='js' code={
        `
        const categoriesLength = categories.length;
        console.log('categoriesLength:', categoriesLength); // categoriesLength: 2
        
        const keywordsLength = keywords.length;
        console.log('keywordsLength:', keywordsLength); // keywordsLength: 3
       `
      }/>
      <Header type="h1" text="Odczytywanie elementów tablicy"/>
      <p>Do odczytania pojedynczego elementu z tablicy użyjemy nazwy tablicy, do której dodamy nawiasy kwadratowe <Code language='js' inline code="[ ]"/> z indeksem elementu, który chcemy pobrać.</p>
      <Code language='js' code={
        `
        const firstCategory = categories[0];
        console.log('firstCategory:', firstCategory); // firstCategory: animals
        `
      }/>
      <p>Często jednak indeks nie jest podawany bezpośrednio, ale przechowywany w zmiennej. W tej sytuacji również używamy nawiasów kwadratowych <Code language='js' inline code="[ ]"/> .</p>
      <Code language='js' code={
        `
        const indexOfSecondCategory = 1;
        const secondCategory = categories[indexOfSecondCategory];
        console.log('secondCategory:', secondCategory); // secondCategory: travel
        `
      }/>

      <Header type="h1" text="Znajdowanie indeksu elementu"/>
      <p>Kiedy chcemy sprawdzić, jaki indeks ma pewna konkretna wartość, możemy to zrobić za pomocą metody <Code inline language='js' code="indexOf"/></p>
      <Code language='js' code={
        `
        const indexOfTravel = categories.indexOf('travel');
        console.log('indexOfTravel:', indexOfTravel); // indexOfTravel: 1
        console.log('value at indexOfTravel:', categories[indexOfTravel]); // value at indexOfTravel: travel
        `
      }/>
      <p>Metoda <Code inline language='js' code='indexOf'/> przydaje się również do sprawdzenia, czy jakaś wartość znajduje się już w tablicy. Jeśli wartość nie zostanie znaleziona w tablicy, <Code inline language='js' code='indexOf'/> zwróci <Code inline language='js' code='-1'/>.</p>
      <Code language='js' code={
        `
        const indexOfCars = categories.indexOf('cars');
        console.log('indexOfCars:', indexOfCars); // indexOfCars: -1
        `
      }/>
      <Header type="h1" text="Usuwanie elementu"/>
      <p>Jest kilka sposobów na usunięcie elementu z tablicy. Jednym z bardziej elastycznych jest metoda <Code inline language='js' code='splice'/></p>
      <Header type="h5" text="Składnia"/>
      <Code language='js' code='const allRemovedValues = myArray.splice(startAtIndex, numberOfElements);'/>
      <Code language='js' code={
        `
        categories.push('fruits');
        categories.push('food');
        categories.push('phones');
        console.log('categories:', categories); // categories: ['animals', 'travel', 'fruits', 'food', 'phones']

        const indexOfFruits = categories.indexOf('fruits');
        console.log('indexOfFruits:', indexOfFruits); // indexOfFruits: 2

        const removedValues = categories.splice(indexOfFruits, 1);
        console.log('removedValues:', removedValues); // removedValues: ['fruits']
        console.log('categories:', categories); // categories: ['animals', 'travel', 'food', 'phones']

        const indexOfFood = categories.indexOf('food');
        console.log('indexOfFood:', indexOfFood); // indexOfFood: 2

        categories.splice(indexOfFood, 2);
        console.log('categories:', categories); // categories: ['animals', 'travel']
        `
      }/>
      <Header type="h1" text="Generowanie tekstu z tablicy"/>
      <p>W prosty sposób możemy wygenerować tekst, składający się z elementów tablicy, połączonych ze sobą dowolnym ciągiem tekstu (string). Wykorzystamy do tego metodę <Code language='js' inline code='join'/>.</p>
      <Code language='js' code={
        `
        const joinedText = keywords.join(', ');
        console.log('joinedText:', joinedText); // joinedText: travel, France, Paris
        `
      }/>
      <p>Możemy wykorzystywać metodę join do szybkiego tworzenia kodu <Code language='js' inline code='HTML'/>, np.:</p>
      <Code language='js' code={`
        const html = '<ul><li>' + keywords.join('</li><li>') + '</li></ul>';
        console.log('html:', html);
        // html: <ul><li>travel</li><li>France</li><li>Paris</li><li>Eiffel Tower</li></ul>
      `}/>
      <Header type="h1" text="Tworzenie tablicy z tekstu"/>
      <p>Operacją odwrotną do <Code language='js' inline code='join'/> jest <Code language='js' inline code='split'/> – dzieli tekst na elementy tablicy, używając podanego ciągu znaków do podzielenia tekstu.</p>
      <Code language='js' code={`
        const subjects = 'cat, cactus, needle';
        const subjectsArray = subjects.split(', ');
        console.log('subjectsArray:', subjectsArray); // subjectsArray: ['cat', 'cactus', 'needle']
      `}/>
      <Header type="h1" text="Iterowanie po tablicy (pętle)"/>
      <p>Kiedy potrzebujemy wykonać jakieś operacje dla wszystkich elementów tablicy, możemy wykorzystać pętle <Code language='js' inline code='for'/> oraz <Code language='js' inline code='for...of'/>. Głównym czynnikiem wpływającym na wybór jednej z tych pętli jest kwestia indeksu – jeśli w pętli nie potrzebujemy korzystać z indeksu elementu, wygodniej będzie nam użyć pętli <Code language='js' inline code='for...of'/>.</p>
      <p>Przykłady użycia pętli do iterowania po tablicach znajdziesz w rozdziale Pętle.</p>
      <Header type="h1" text="Tablice wielowymiarowe"/>
      <p>Elementami tablicy mogą być np. liczby czy teksty, ale mogą to być również obiekty i tablice. Przykład takiej wielopoziomowej struktury danych znajdziesz w rozdziale o Obiektach.</p>
      <Header type="h1" text="Przydatne metody i właściwości"/>
      <Header type="h5" text="Rozmiar tablicy"/>
      <table>
        <tr>
          <th>Metoda</th>
          <th>Opis</th>
        </tr>
        <tr>
          <td><Code inline language='js' code='length'/></td>
          <td>podaje liczbę elementów w tablicy</td>
        </tr>
      </table>
      <Header type="h5" text="Znajdowanie indeksu elementu"/>
      <table>
        <tr>
          <th>Metoda</th>
          <th>Opis</th>
        </tr>
        <tr>
          <td><Code inline language='js' code='indexOf()'/></td>
          <td>znajduje indeks podanego elementu</td>
        </tr>
      </table>
      <Header type="h5" text="Dodawanie i usuwanie elementów"/>
      <table>
        <tr>
          <th>Metoda</th>
          <th>Opis</th>
        </tr>
        <tr>
          <td><Code inline language='js' code='push()'/></td>
          <td>dodaje element na końcu tablicy</td>
        </tr>
        <tr>
          <td><Code inline language='js' code='pop()'/></td>
          <td>usuwa element z końca tablicy</td>
        </tr>
        <tr>
          <td><Code inline language='js' code='shift()'/></td>
          <td>usuwa element z początku tablicy</td>
        </tr>
        <tr>
          <td><Code inline language='js' code='unshift()'/></td>
          <td>dodaje element na początku tablicy</td>
        </tr>
      </table>
      <Header type="h5" text="Zmiana kolejności elementów"/>
      <table>
        <tr>
          <th>Metoda</th>
          <th>Opis</th>
        </tr>
        <tr>
          <td><Code inline language='js' code='sort()'/></td>
          <td>sortuje elementy tablicy</td>
        </tr>
        <tr>
          <td><Code inline language='js' code='reverse()'/></td>
          <td>odwraca kolejność elementów</td>
        </tr>
      </table>
      <Header type="h5" text="Modyfikacja tablicy"/>
      <table>
        <tr>
          <th>Metoda</th>
          <th>Opis</th>
        </tr>
        <tr>
          <td><Code inline language='js' code='map()'/></td>
          <td>zmienia każdy element tablicy za pomocą funkcji</td>
        </tr>
        <tr>
          <td><Code inline language='js' code='filter()'/></td>
          <td>filtruje elementy tablicy</td>
        </tr>
      </table>
      <Header type="h5" text="Dzielenie i łączenie tablic"/>
      <table>
        <tr>
          <th>Metoda</th>
          <th>Opis</th>
        </tr>
        <tr>
          <td><Code inline language='js' code='slice()'/></td>
          <td>tworzy nową tablicę z części elementów</td>
        </tr>
        <tr>
          <td><Code inline language='js' code='concat()'/></td>
          <td>tworzy nową tablicę z elementów wielu tablic</td>
        </tr>
      </table>
      <Header type="h5" text="Dzielenie i łączenie tablic"/>
      <table>
        <tr>
          <th>Metoda</th>
          <th>Opis</th>
        </tr>
        <tr>
          <td><Code inline language='js' code='join()'/></td>
          <td>łączy elementy tablicy w tekst</td>
        </tr>
      </table>
    </div>
  );
} 

export default Arrays;
