import React from 'react';
import Header from '../Header';
import Code from '../Code';

function Comments(props) {

    const multiLineComent = `/* A multi-line
    comment */`

  return (
    <div className="section">
        <p>W JavaScripcie istnieją dwa rodzaje komentarzy: liniowe i blokowe.</p>
        <Header type="h2" text="Komentarz liniowy"/>
        <Code language="javascript" code='// A single line comment'></Code>
        <p>Zajmuje on jedną linię tekstu: cała treść od znaków {<Code language="html" inline code='//'/>} do końca wiersza będzie ignorowana przez przeglądarkę.</p>
        <Header type="h2" text="Komentarz blokowy"/>
        <Code language="javascript" code={multiLineComent} />
        <p>Może on zajmować wiele linii: cała treść między znakami /* oraz */ będzie ignorowana przez przeglądarkę.</p>
    </div>
  );
} 

export default Comments;
