import React from 'react';
import Header from '../Header';
import Code from '../Code';

function AjaxJson(props) {
  return (
    <div className="section">
      <p>JSON jest formatem zapisu danych, często używanym w programowaniu JS. Z tego względu posiada własną bibliotekę <Code language='js' inline code='JSON'/>, która pomaga nam w konwertowaniu tablicy lub obiektu na JSON i vice versa.</p>
      <Header type="h1" text="Generowanie JSONa"/>
      <p>Generujemy kod JSON wykonując metodę <Code language='js' inline code='JSON.stringify'/>, podając jej za argument tablicę lub obiekt, który ma być przekonwertowany na JSON.</p>
      <Code language="js" code={`
      const products = [
        {
          id: 1, 
          name: 'Pencil', 
          attributes: {
            material: 'wood/graphite',
          }, 
        },
        {
          id: 2, 
          name: 'Pen', 
          attributes: {
            material: 'metal/plastic',
          },
        },
      ];
      
      const productsJSON = JSON.stringify(products);
      console.log(productsJSON); // [{"id":1,"name":"Pencil","attributes":{"material":"wood/graphite"}},{"id":2,"name":"Pen","attributes":{"material":"metal/plastic"}}]
      `}/>
      <p>Otrzymujemy w ten sposób ciąg znaków w formacie JSON. Nie możemy bezpośrednio korzystać z danych w tym formacie, czyli np. sprawdzić nazwy pierwszego produktu. Możemy za to dane w formacie JSON zapisać lub wysłać jako tekst.</p>
      <Header type="h1" text="Parsowanie JSONa"/>
      <p>Odwrotną operacją jest parsowanie – metodzie <Code language='js' inline code='JSON.parse'/> przekazujemy JSON, a zwraca on obiekt lub tablicę z danymi.</p>
      <Code language='js' code={`
      const productsParsed = JSON.parse(productsJSON);
      console.log(productsParsed[0].name); // "Pencil"
      `}/>
      <p>Parsujemy dane w sytuacji, kiedy otrzymaliśmy dane w formacie JSON i chcemy zacząć z nich korzystać.</p>
      <Header type="h1" text='"Ładne" formatowanie JSONa'/>
      <p>Czytanie danych w formacie JSON nie należy do przyjemnych, kiedy są one zapisane w domyślnym, jedno-linijkowym formacie.</p>
      <Code language='js' code={`
      console.log(JSON.stringify(products)); 
      // [{"id":1,"name":"Pencil","attributes":{"material":"wood/graphite"}},{"id":2,"name":"Pen","attributes":{"material":"metal/plastic"}}]
      `}/>
      <p>Jeśli jednak, metodzie <Code language='js' inline code='JSON.stringify'/> podamy jako drugi argument <Code language='js' inline code='null'/>, a jako trzeci – ciąg znaków zawierający dwie spacje <Code language='js' inline code="'  '"/>, to otrzymamy znacznie bardziej przyjaźnie sformatowany JSON:</p>
      <Code language='js' code={`
      console.log(JSON.stringify(products, null, '  ')); 
      /*
      [
        {
          "id": 1,
          "name": "Pencil",
          "attributes": {
            "material": "wood/graphite"
          }
        },
        {
          "id": 2,
          "name": "Pen",
          "attributes": {
            "material": "metal/plastic"
          }
        }
      ]
      */      
      `}/>
      <p>To nadal jest ciąg znaków zawierający dane w formacie JSON, ale tym razem został on przedstawiony w wielu liniach i z wcięciami, pozwalając na znacznie łatwiejsze zapoznanie się z jego zawartością.</p>
      <p>Jeśli nie generujesz kodu JSON samodzielnie, tylko otrzymujesz go np. z serwera, możesz znaleźć wiele narzędzi do zmiany formatu na bardziej czytelny, wpisując w Google frazę "json beautifier".</p>
    </div>
  );
} 

export default AjaxJson;
