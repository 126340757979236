import React from 'react';
import Header from '../Header';
import Code from '../Code';

function Output(props) {

    const alert_example= `
    function getSum(a, b) {
        const sum = a + b;
        return sum;
    }
    
    alert( getSum(5, 7) );
    `;

  return (
    <div className="section">
        <p>JS pozwala na różne sposoby wyświetlać treść na stronie. Poniższych technik można używać zarówno do sprawdzania działania skryptów, jak i komunikacji z użytkownikiem strony.</p>
        <Header type="h1" text="console.log();"/>
        <p>Ta funkcja wyświetla w konsoli wszystkie przekazane do niej parametry. W poniższy sposób możemy drukować w konsoli zarówno teksty, jak i zmienne/stałe.</p>
        <Header type="h5" text="Składnia"/>
        <Code language="javascript" code='console.log(x);'></Code>
        <Header type="h5" text="Przykład"/>
        <img width="25%" alt="debugging" src="output.png"/>
        <Header type="h1" text="alert();"/>
        <Header type="h5" text="Składnia"/>
        <p>Jest to funkcja podobna do <Code language="javascript" inline code='console.log()'/>, z tą różnicą, że zamiast w konsoli, treść wyświetlana jest w alercie (wyskakującym okienku).</p>
        <Code language="javascript" code="alert(x);"/>
        <Header type="h5" text="Przykład"/>
        <p>Wynikiem wpisania w konsoli poniższego skryptu:</p>
        <Code language="javascript" code={alert_example}/>
        <p>będzie następujący alert:</p>
        <img width="45%" alt="debugging" src="output1.png"/>
        <Header type="h1" text="confirm();"/>
        <p>Ta funkcja wyświetli na ekranie alert z dwoma przyciskami, umożliwiającymi potwierdzenie lub odrzucenie danej akcji.</p>
        <Header type="h5" text="Składnia"/>
        <Code language="javascript" code='confirm("Are you sure?");'/>
        <Header type="h5" text="Przykład"/>
        <img width="45%" alt="debugging" src="output2.png"/>
        <p>Wynikiem wpisania w konsoli powyższego skryptu będzie następujący alert:</p>
        <Header type="h1" text="prompt();"/>
        <p>Ta funkcja wyświetli na ekranie alert z inputem tekstowym i dwoma przyciskami.</p>
        <Header type="h5" text="Składnia"/>
        <Code language="javascript" code='prompt("How old are you?","0");'/>
        <p>Funkcja przyjmuje dwa argumenty: pierwszy to treść wyświetlana w alercie, a drugi to domyślna zawartość pola tekstowego.</p>
        <Header type="h5" text="Przykład"/>
        <p>Wynikiem wpisania w konsoli powyższego skryptu będzie następujący alert:</p>
        <img width="45%" alt="debugging" src="output3.png"/>
    </div>
  );
} 

export default Output;
