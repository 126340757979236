import React from 'react';
import Header from '../Header';
import Code from '../Code';

function ModifyDomElements(props) {

    const classList_example= `
        const div = document.querySelector('#testing-div');

        div.classList.add('active');
        console.log(div.classList.contains('active')); // true
        
        div.classList.remove('active');
        console.log(div.classList.contains('active')); // false
        
        div.classList.toggle('active');
        console.log(div.classList.contains('active')); // true
    `;

    const innerHTML_example = `
        const div = document.querySelector('#testing-div');

        console.log(div.innerHTML);
        
        div.innerHTML = '<strong>Hello world!</strong>';
    `;

    const insertAdjacentHTML_example = `
        const div = document.querySelector('#testing-div');
        const newCode = '<strong>Hello world!</strong>';
        
        div.insertAdjacentHTML('beforeend', newCode);
    `;

    const remove_example = `
        const galleryDiv = document.querySelector('div.gallery');
        galleryDiv.remove();
    `;

  return (
    <div className="section">
        <p>W poniższych przykładach zakładamy, że znasz już podstawowe informacje z zakresu wyszukiwania elementów DOM.</p>
        <Header type="h1" text="classList"/>
        <p>Parametr <Code language="js" inline code="classList"/> zawiera zestaw metod, które pozwalają na operacje na klasach danego elementu.</p>
        <Code language="javascript" code='<script src="script.js"></script>'></Code>
        <table>
            <tr>
                <th>Metoda</th>
                <th>Zastosowanie</th>
            </tr>
            <tr>
                <td><Code language='js' inline code="add"></Code></td>
                <td>dodanie klasy</td>
            </tr>
            <tr>
                <td><Code language='js' inline code="remove"></Code></td>
                <td>usunięcie klasy</td>
            </tr>
            <tr>
                <td><Code language='js' inline code="toggle"></Code></td>
                <td>dodanie klasy jeśli jej nie było, i vice versa</td>
            </tr>
            <tr>
                <td><Code language='js' inline code="contains"></Code></td>
                <td>sprawdzenie czy element zawiera daną klasę</td>
            </tr>
        </table>
        <Header type="h5" text="Przykłady:"/>
        <Code language="js" code={classList_example}/>
        <Header type="h1" text="innerHTML"/>
        <Code language="js" code={innerHTML_example}/>
        <Header type="h1" text="insertAdjacentHTML"/>
        <p>Metoda <Code language="js" inline code="insertAdjacentHTML"/> służy do wstawiania kodu HTML bez usuwania zawartości danego elementu. Jako pierwszy argument podaje się tekst, który decyduje o miejscu wstawienia kodu HTML.</p>
        <table>
            <tr>
                <th>Argument</th>
                <th>Miejsce wstawienia kodu</th>
            </tr>
            <tr>
                <td><Code language='js' inline code="beforebegin"></Code></td>
                <td>przed elementem</td>
            </tr>
            <tr>
                <td><Code language='js' inline code="afterbegin"></Code></td>
                <td>w elemencie, na początku jego zawartości</td>
            </tr>
            <tr>
                <td><Code language='js' inline code="beforeend"></Code></td>
                <td>w elemencie, na końcu jego zawartości</td>
            </tr>
            <tr>
                <td><Code language='js' inline code="afterend"></Code></td>
                <td>po elemencie</td>
            </tr>
        </table>
        <Header type="h5" text="Składnia:"/>
        <Code language="js" code={insertAdjacentHTML_example}/>


        <Header type="h1" text="createElement"/>
        <p>Tworzenie nowego elementu DOM można osiągnąć za pomocą metody <Code inline language="js" code="createElement"/> wykonanej na obiekcie <Code inline language="js" code="document"/>. Element zostanie jedynie stworzony i zwrócony – nie zostanie dodany na stronie.</p>
        <Header type="h5" text="Składnia:"/>
        <Code language="js" code="const newDiv = document.createElement('div');"/>

        <Header type="h1" text="remove"/>
        <p>Usunięcie elementu z DOM można wykonać za pomocą metody <Code inline language="js" code="remove"/> wykonanej na elemencie, który ma zostać usunięty.</p>
        <Header type="h5" text="Składnia:"/>
        <Code language="js" code={remove_example}/>
    </div>
  );
} 

export default ModifyDomElements;
