import React from 'react';
import '../App.css';



function Header(props) {

    const generateHeader = (type) => {
        switch (type){
            case 'h1':
                return <h1 className="header" id={props.text.toLowerCase().replace(" ", "-")}>{props.text}</h1>
            case 'h2':
                return <h2 className="header">{props.text}</h2>
            case 'h3':
                return <h3 className="header">{props.text}</h3>
            case 'h4':
                return <h4 className="header">{props.text}</h4>
            case 'h5':
                return <h5 className="header">{props.text}</h5>
            default:
                return <h1 className="header">{props.text}</h1>
        }
    }

return (
    <div>
        {generateHeader(props.type)}
    </div>
  );
} 

export default Header;
