import React from 'react';
import Header from '../Header';
import Code from '../Code';

function Conditions(props) {
  return (
    <div className="section">
        <p>Wyrażenia warunkowe umożliwiają zdefiniowanie, jaka akcja ma zostać wykonana, gdy zostanie spełniony (lub nie) dany warunek.</p>
        <Header type="h1" text="If...else if...else"/>
        <p>Blok kodu wewnątrz wyrażenia <Code inline language='js' code='if'/> zostanie wykonany, gdy zostanie spełniony pierwszy warunek. Reszta warunków nie zostanie wtedy sprawdzona. Jeżeli pierwszy warunek nie zostanie spełniony, skrypt będzie sprawdzał kolejne. Jeżeli nie zostanie spełniony żaden z warunków, zostanie wykonany blok kodu wewnątrz wyrażenia <Code inline language='js' code='else'/>.</p>
        <p>Bloki <Code language='js' inline code='else if'/> i <Code language='js' inline code='else'/> są opcjonalne.</p>
        <Header type="h5" text="Przykład"/>
        <Code language='js' code={
            `
            if (condition1) {
                // code to execute if condition1 is true
            } else if (condition2) {
                // code to execute if condition2 is true
            } else {
                // code to execute if no condition is true
            }
            `
        }/>

        <Header type="h1" text="Short if"/>
        <p>Tzw. short if nie jest wyrażeniem, tylko operatorem. Jego pełna nazwa to operator warunkowy, lub operator trójczłonowy (conditional/ternary operator). Informacje na jego temat znajdziesz w rozdziale dotyczącym operatorów.</p>
        <Header type="h1" text="Switch"/>
        <p>Wyrażenie <Code language='js' inline code='switch'/> można rozumieć jako alternatywny zapis złożonej instrukcji <Code language='js' inline code='if...else'/>. Służy on do sprawdzania ciągu warunków i wykonania odpowiednich akcji w zależności od ich wyniku.</p>
        <Header type="h5" text="Przykład"/>
        <Code language='js' code={
            `
            const question = prompt('Do you like cats?');
            switch (question) {
                case 'Yes': // condition1
                    alert('Great, me too!');  // instruction1
                    break;
                case 'No': // condition2
                    alert('Really?');  // instruction2
                    break;
                default:
                    alert('You did not give a clear answer.'); // instruction3
            }
            `
        }/>
        <p>W powyższym kodzie wywołujemy prompt (okienko) z pytaniem zdefiniowanym w stałej <Code language='js' inline code='question'/>. Następnie skrypt porównuje udzieloną odpowiedź ze zdefiniowanymi warunkami i podejmuje odpowiednie działanie:</p>
        <ul>
            <li>jeżeli udzielona odpowiedź jest równa "Yes" (<Code inline language='js' code='condition1'/> jest spełniony), to wykonuje <Code inline language='js' code='instruction1'/> i przerywa dalsze wykonywanie bloku <Code inline language='js' code='switch'/> (za przerwanie jest odpowiedzialna instrukcja <Code inline language='js' code='break'/>),</li>
            <li>jeżeli udzielona odpowiedź jest równa "No" (<Code inline language='js' code='condition2'/> jest spełniony), to wykonuje <Code inline language='js' code='instruction2'/> i przerywa dalsze wykonywanie bloku <Code inline language='js' code='switch'/>,</li>
            <li>jeżeli żaden warunek nie jest spełniony, wykonuje blok <Code inline language='js' code='default'/> (<Code inline language='js' code='instruction3'/>) i kończy blok <Code inline language='js' code='switch'/>.</li>
        </ul>
        <p>Blok <Code inline language='js' code='default'/> jest opcjonalny.</p>
    </div>
  );
} 

export default Conditions;
