import React from 'react';
import Header from '../Header';
import Code from '../Code';

function TargetDomElements(props) {
  return (
    <div className="section">
        <p>DOM oznacza Document Object Model, czyli Obiektowy model dokumentu. Kiedy plik .html zostanie otwarty w przeglądarce, na jego podstawie zbuduje się DOM. 
            Dlatego div w pliku .html jest elementem HTML, zaś w przeglądarce jest elementem DOM.</p>
        <p>Kiedy za pomocą JavaScriptu będziemy dodawać, usuwać lub zmieniać jakieś elementy strony, będą to operacje na DOM, nie na HTML.</p>
        <p>Aby zmodyfikować element DOM za pomocą JS, musimy go wskazać, co umożliwią nam opisane poniżej funkcje.</p>
        <Header type="h1" text="getElementById"/>
        <p>Ta funkcja umożliwia wskazanie elementu o konkretnym identyfikatorze (id).</p>
        <Header type="h5" text="Składnia"/>
        <Code language="javascript" code="const contentBox = document.getElementById('content-box');"></Code>
        <p>Powyższy kod wybierze z DOM element o id <Code language="javascript" inline code="content-box"/> i zapisze go w stałej.</p>

        <Header type="h1" text="querySelector"/>
        <p>Ta funkcja zwróci pierwszy element w dokumencie o danej klasie.</p>
        <Header type="h5" text="Składnia"/>
        <Code language="javascript" code="const mainHeader = document.querySelector('.main-header');"></Code>
        <p>Powyższy kod wybierze z DOM element o klasie <Code language="javascript" inline code="main-header"/> i zapisze go w stałej.</p>

        <Header type="h1" text="querySelectorAll"/>
        <p>Ta funkcja wyszuka wszystkie elementy, pasujące do selektora.</p>
        <Header type="h5" text="Składnia"/>
        <Code language="javascript" code="const links = document.querySelectorAll('.titles a');"></Code>
        <p>owyższy kod wybierze z DOM wszystkie elementy pasujące do selektora <Code language="javascript" inline code=".titles a"/> i zapisze go w stałej.</p>
    </div>
  );
} 

export default TargetDomElements;
