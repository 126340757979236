import React from 'react';
import Header from '../Header';
import Code from '../Code';

function GoodPractices(props) {

  const words = ["break", "case", "catch", "class", "const", "continue", "debugger", "default", "delete", "do", "else", "enum", "export", "extends", "false", "finally", "for", "function", "if", "implements", "import", "in", "instanceof", "interface", "let", "new", "null", "package", "private", "protected", "public", "return", "static", "super", "switch", "this", "throw", "true", "try", "typeof", "var", "void", "while", "with", "yield"]

  return (
    <div className="section">
      <p>Poniżej znajduje się lista kilku dobrych praktyk, o których warto pamiętać podczas pisania skryptów.</p>
        <Header type="h1" text="Nazwy"/>
        <p>Dla nazw zmiennych i funkcji:</p>
        <ul>
          <li>używaj formatu <Code language="js" inline code="camelCase"/> (bez spacji pomiędzy poszczególnymi słowami, każde kolejne słowo zaczynając wielką literą),</li>
          <li>używaj tylko języka angielskiego,</li>
          <li>używaj tylko znaków alfanumerycznych (cyfr i liter, ale bez polskich znaków).</li>
        </ul>
        <Header type="h1" text="Spacje"/>
        <p>Wstawiaj spację przed i za operatorami (czyli znakami <Code language="javascript" inline code='='/>, <Code language="javascript" inline code='+'/>, <Code language="javascript" inline code='-'/>, <Code language="javascript" inline code='*'/>, <Code language="javascript" inline code='/'/>) oraz po przecinkach.</p>
        
        <Header type="h1" text="Średniki"/>
        <p>Wstawiaj średnik na końcu każdej linii kodu, z wyjątkiem:</p>
        <ul>
          <li>deklaracji funkcji nazwanych, np. <Code language="js" inline code='function myFunc(){}'/>,</li>
          <li>bloków <Code language="js" inline code='if'/>, <Code language="js" inline code='else if'/> i <Code language="js" inline code='else'/>,</li>
          <li>pętli.</li>
        </ul>

        <Header type="h1" text="Wcięcia"/>
        <p>Używaj dwóch spacji, aby tworzyć wcięcia w blokach kodu.</p>

        <Header type="h1" text="Strict mode"/>
        <p>Dobrą praktyką jest uruchamianie kodu JS w "trybie ścisłym" poprzez umieszczenie na początku każdego pliku ze skryptami następującej linii:</p>
        <Code language='js' code="'use strict';"/>
        <p>Dzięki tej deklaracji pomyłki, które normalnie nie wywołałyby błędu, będą traktowane jak błąd i wyświetlane na czerwono w konsoli.</p>
       
        <Header type="h1" text="Słowa zastrzeżone"/>
        <p>Poniższych słów <b>nie można</b> używać jako nazw funkcji lub zmiennych, gdyż mają one swoje odgórnie zdefiniowane znaczenie w JS.</p>
        <p>
          {words.map((word, index) => <span><Code inline language='js' code={word}/>{index === words.length-1 ? "." : ", "}</span>)}
        </p>
    </div>
  );
} 

export default GoodPractices;
