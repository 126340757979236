import React from 'react';
import Header from '../Header';
import Code from '../Code';

function OopClasses(props) {
  return (
    <div className="section">
      <p>Klasa (class) jest szablonem obiektu. Dzięki niej możemy stworzyć wiele instancji (instance), które będą działać wedle tego szablonu.</p>
      <Header type="h1" text="Tworzenie klasy"/>
      <p>Klasę tworzymy za pomocą słowa kluczowego <Code language='js' inline code='class'/>. Wewnątrz jej definicji możemy w skrótowy sposób deklarować jej metody (methods) bez użycia słowa <Code language='js' inline code='function'/>, jak pokazaliśmy w poniższym przykładzie.</p>
      <p>Przy tworzeniu klasy, kluczowe jest wykorzystanie obiektu <Code inline language='js' code='this'/>, który reprezentuje pojedynczą instancję, czyli obiekt stworzony na podstawie klasy. Dzięki temu możemy danej instancji przypisywać właściwości (wartości, dane) oraz uruchamiać jej metody – zwykle odziedziczone z klasy. Dla łatwiejszego zrozumienia przykładów będziemy zapisywać obiekt <Code inline language='js' code='this'/> w stałej <Code inline language='js' code='thisEmployee'/> (ten pracownik).</p>
      <p>Kolejnym charakterystycznym elementem klasy jest metoda <Code inline language='js' code='constructor'/> – ta funkcja jest uruchamiana zawsze w momencie tworzenia nowej instancji i służy do jej zainicjowania. Argumenty przekazane przy tworzeniu nowej instancji są dostępne wyłącznie w konstruktorze – dlatego ważne jest, aby zapisać je do właściwości instancji. Dzięki temu, te wartości będą dostępne w innych metodach.</p>
      <Code language='js' code={`
      class Employee{
        constructor(name, age, yearlySalary){
          const thisEmployee = this;
      
          thisEmployee.name = name;
          thisEmployee.age = age;
          thisEmployee.yearlySalary =yearlySalary;
      
          thisEmployee.calculateMonthlySalary();
        }
      
        calculateMonthlySalary(){
          const thisEmployee = this;
      
          thisEmployee.monthlySalary = thisEmployee.yearlySalary / 12;
        }
      
        showDetails(){
          const thisEmployee = this;
      
          console.log(thisEmployee.name, thisEmployee.age, thisEmployee.monthlySalary);
        }
      }
      `}/>
      <Header type="h1" text="Tworzenie instancji"/>
      <p>Instancję klasy tworzymy za pomocą słowa kluczowego <Code inline language='js' code='new'/>, nazwy klasy, oraz argumentów przekazywanych do konstruktora klasy.</p>
      <Code language='js' code={`
      const john = new Employee('John Doe', 20, 12000);

      console.log('john:', john);
      // john: Employee {name: "John Doe", age: 20, yearlySalary: 12000, monthlySalary: 1000}
      
      console.log('john.age:', john.age);
      // john.age: 20
      
      john.showDetails(); 
      // 'John Doe', 20, 1000
      
      const jane = new Employee('Jane Stevens', 35, 18000);
      
      console.log('jane:', jane); 
      // jane: Employee {name: "Jane Stevens", age: 35, yearlySalary: 18000, monthlySalary: 1500}
      
      console.log('jane.age:', jane.age);
      // jane.age: 35
      
      jane.showDetails(); 
      // 'Jane Stevens', 35, 1500
      `}/>
      <Header type="h1" text="Zmiana właściwości instancji"/>
      <p>Jak widzieliśmy w powyższym przykładzie, każda instancja ma własne właściwości. Możemy je dowolnie zmieniać, nie wpływając na pozostałe instancje.</p>
      <Code language='js' code={`
      console.log('john.age:', john.age);
      // john.age: 20
      console.log('jane.age:', jane.age);
      // jane.age: 35
      
      john.age++;
      
      console.log('john.age:', john.age);
      // john.age: 21 <== CHANGED
      console.log('jane.age:', jane.age);
      // jane.age: 35
      `}/>
    </div>
  );
} 

export default OopClasses;
