import React from 'react';
import Header from '../Header';
import Code from '../Code';

function DataTypes(props) {
  return (
    <div className="section">
        <p>W JavaScripcie istnieją dwa typy danych: proste i złożone (inaczej: referencyjne).</p>
        <Header type="h1" text="Typy proste"/>
        <p>W poniższych typach danych, wartości przypisane są bezpośrednio do zmiennej/stałej.</p>
        <table>
            <tr>
                <th>Przykład</th>
                <th>Opis</th>
            </tr>
            <tr>
                <td><Code language="js" inline code='const myNumber = 1;'></Code></td>
                <td>liczba (integer)</td>
            </tr>
            <tr>
                <td><Code language="js" inline code='const myText = "text";'></Code></td>
                <td>tekst (string)</td>
            </tr>
            <tr>
                <td><Code language="js" inline code='const myBool = true;'></Code></td>
                <td>logiczny – prawda/fałsz (boolean)</td>
            </tr>
            <tr>
                <td><Code language="js" inline code='const myNull = null;'></Code></td>
                <td>nic (null)</td>
            </tr>
        </table>
        <Header type="h1" text="Typy złożone"/>
        <p>W złożonych typach danych, zmienne/stałe nie mają bezpośrednio przypisanej wartości. Zamiast tego, wskazują na miejsce w pamięci, gdzie przechowywane są te dane.</p>
        <table>
            <tr>
                <th>Przykład</th>
                <th>Opis</th>
            </tr>
            <tr>
                <td><Code language="js" inline code='const myArr = ["one", "two", 3];'></Code></td>
                <td>tablica (array)</td>
            </tr>
            <tr>
                <td><Code language="js" inline code='const myObj = {"one":1, 2:"two"};'></Code></td>
                <td>obiekt (object)</td>
            </tr>
        </table>
        
    </div>
  );
} 

export default DataTypes;
